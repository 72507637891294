import React, { useReducer, useEffect, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { graphql, PageProps } from 'gatsby';

import { mozambique } from '@constants';
import {
    MozambiqueMap,
    MainWrapper,
    MobileScreenMessage,
    Header,
    MozambiqueReport,
    Sidebar,
    TFilter,
    TMozambiqueReportData,
    Seo,
} from '@components';
import {
    StyledReportContainer,
    TInitialState,
    TAction,
    TMozambiquePageProps,
} from '.';

const INITIAL_STATE: TInitialState = {
    filter: {
        category: mozambique.filters.categoryOptions[0].value,
        fromDate: mozambique.filters.dates.from,
        toDate: mozambique.filters.dates.to,
    },
    incidents: undefined,
    reportData: undefined,
    totalIncidents: undefined,
};

function reducer(state: TInitialState, action: TAction) {
    switch (action.type) {
        case 'updateFilters':
            return { ...state, filter: action.value };
        case 'setIncidents':
            return { ...state, incidents: action.value };
        case 'setReportData':
            return { ...state, reportData: action.value };
        case 'setTotalIncidents':
            return { ...state, totalIncidents: action.value };
        default:
            return INITIAL_STATE;
    }
}

export default function Mozambique({ data }: PageProps<TMozambiquePageProps>) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const { allMozambiqueSecurityIncidentsCsv, markdownRemark } = data;

    function handleFilterChange(filter: TFilter): void {
        dispatch({ type: 'updateFilters', value: filter });
    }

    function handleSettingReportData(reportData: TMozambiqueReportData): void {
        dispatch({ type: 'setReportData', value: reportData });
    }

    useEffect(() => {
        dispatch({
            type: 'setIncidents',
            value: allMozambiqueSecurityIncidentsCsv.nodes,
        });
    }, []);

    function handleSettingTotalIncidents(totalIncidents: number): void {
        dispatch({ type: 'setTotalIncidents', value: totalIncidents });
    }

    return (
        <Fragment>
            <Seo
                title={markdownRemark.frontmatter.title}
                description={markdownRemark.frontmatter.metaDescription}
            />
            <Header
                title={markdownRemark.frontmatter.heading}
                funderLogos={mozambique.funders.filter(
                    funder => funder.view === 'header'
                )}
            />
            <MainWrapper>
                <Grid item xs={2}>
                    <Sidebar
                        filter={state.filter}
                        setFilter={handleFilterChange}
                        categoryOptions={mozambique.filters.categoryOptions}
                        description={markdownRemark.frontmatter.sideBarCopy.replace(
                            'INSERT YEAR',
                            `${new Date().getFullYear()}`
                        )}
                        funderLogos={mozambique.funders.filter(
                            funder => funder.view === 'sidebar'
                        )}
                    />
                </Grid>
                <Grid item xs={8}>
                    <MozambiqueMap
                        filter={state.filter}
                        incidents={state.incidents}
                        setReportData={handleSettingReportData}
                        setTotalIncidentsCount={handleSettingTotalIncidents}
                        dataSourceAttribution={
                            markdownRemark.frontmatter.dataSourceAttribution
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <StyledReportContainer>
                        <MozambiqueReport
                            filter={state.filter}
                            reportData={state.reportData}
                        />
                    </StyledReportContainer>
                </Grid>
            </MainWrapper>
            <MobileScreenMessage />
        </Fragment>
    );
}

export const pageQuery = graphql`
    query MozambiquePageTemplate {
        markdownRemark(frontmatter: { path: { eq: "mozambique" } }) {
            frontmatter {
                path
                title
                heading
                metaDescription
                sideBarCopy
                dataSourceAttribution
            }
        }
        allMozambiqueSecurityIncidentsCsv {
            nodes {
                incidentNumber
                editedIncidentDescription
                isoDate
                isoCode
                latitude
                longitude
                admin1Pcode
                location
                perpetratorMap
                weaponUseMap
                individualsAffected
                crime
                operationalSpace
                roadSafetyAccident
                nearMiss
                education
                health
                foodSecurity
                aidMissionAffected
                totalAidWorkerKilled
                totalAidWorkerKidnapped
                totalAidWorkerInjured
                totalHealthWorkerKilled
                totalHealthWorkerKidnapped
                totalEducatorKilled
                totalEducatorKidnapped
            }
        }
    }
`;
